
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
function CampaignManagement() {


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/app/campaign_management"}>Campaign Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="ti ti-list-check me-2"></i>List of Campaigns
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="ti ti-plus me-2"></i>Add New Campaign
                                                            </a>
                                                        </li>


                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="ti ti-message-2 me-2"></i>Message Template
                                                            </a>
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-xxl-12">
                                                            <div className="card">
                                                                <div className="card-body position-relative">
                                                                    <div className="col-md-3 col-xs-12  mb-3">
                                                                        <br />
                                                                        <input className="form-control" placeholder="Search here" value={''} onChange={(e) => { }} />

                                                                    </div>
                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable value={[]} header={header} emptyMessage="No campaigns found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>


                                                                            <Column field="name" header="Name"></Column>
                                                                            <Column field="type" header="Channel Mode"></Column>
                                                                            <Column field="message" header="Message"></Column>
                                                                            <Column field="facility" header="Facility"></Column>
                                                                            <Column field="date" header="Scheduled Date"></Column>
                                                                            <Column field="time" header="Scheduled Time"></Column>
                                                                            <Column field="status" header="Status"></Column>
                                                                        </DataTable>


                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">

                                                                <div className="card-body">
                                                                    <div className="row">

                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Campaign Name</label>
                                                                                <input type="text" className="form-control" value="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Channel Mode</label>
                                                                                <select type="text" className="form-control" >
                                                                                    <option>-- Select --</option>
                                                                                    <option>Email</option>
                                                                                    <option>SMS</option>
                                                                                    <option>Whatsapp</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Facility</label>
                                                                                <select type="text" className="form-control" >
                                                                                    <option>-- Select --</option>
                                                                                    <option>Knights Bridge</option>
                                                                                    <option>Toll</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>




                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Scheduled Date</label>
                                                                                <input type="date" className="form-control" value="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Scheduled Time</label>
                                                                                <input type="time" className="form-control" value="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Behaviour</label>
                                                                                <select type="text" className="form-control" >
                                                                                    <option>-- Select --</option>
                                                                                    <option>Recurring</option>
                                                                                    <option>One Time</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Message Template</label>
                                                                                <select type="text" className="form-control" >
                                                                                    <option>-- Select --</option>
                                                                                    <option>Message 1</option>
                                                                                    <option>Message 2</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Message</label>
                                                                                <textarea type="text" className="form-control" value="" />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 text-end btn-page">

                                                            <div className="btn btn-primary">Add</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-4">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5>Add Template</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Title</label>
                                                                        <input type="title" className="form-control" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Message</label>
                                                                        <textarea type="text" className="form-control" />
                                                                    </div>
                                                                    <div className=" text-end btn-page">
                                                                        
                                                                        <div className="btn btn-primary">Add</div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <h5>Templates</h5>
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item"><strong>Message 1</strong><i className="ti ti-trash text-danger f-16 me-2" style={{float:'right'}}></i><br/>
                                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></li>
                                                                        <li className="list-group-item"><strong>Message 1</strong><i className="ti ti-trash text-danger f-16 me-2" style={{float:'right'}}></i><br/>
                                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default CampaignManagement