import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
  name: 'Authentication',
  initialState: {
    loginEmail: '',
    loginPassword: '',
    forgotEmail: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode:'',
    spinner: false,
  },
  reducers: {
    inputLoginEmail: (state, action) => {
      state.loginEmail = action.payload
    },
    inputLoginPassword: (state, action) => {
      state.loginPassword = action.payload
    },
    inputForgotEmail: (state, action) => {
      state.forgotEmail = action.payload
    },
    inputNewPassword: (state, action) => {
      state.newPassword = action.payload
    },
    inputConfirmNewPassword: (state, action) => {
      state.confirmNewPassword = action.payload
    },
    updateSpinner: (state, action) => {
      state.spinner = action.payload
    },
   

  },
})

// Action creators are generated for each case reducer function
export const { inputLoginEmail, inputLoginPassword, inputForgotEmail,inputNewPassword,inputConfirmNewPassword, updateSpinner } = authenticationSlice.actions

export default authenticationSlice.reducer