
export const backend_url = 'https://api.payserve.co.ke'
export const checkEmailAndPasswordURL = '/api/auth/check_email_and_password'
export const loginURL = '/api/auth/login'
export const forgotPasswordURL = '/api/auth/forgot_password'
export const resetPasswordURL = '/api/auth/reset_password'
export const codeVerificationURL = '/api/auth/otp'
export const addWaterConcentratorURL = '/api/core/water/add_concentrator'
export const getWaterConcentratorsURL = '/api/core/water/get_concentrators'
export const importWaterConcentratorsURL = '/api/core/water/import_concentrators'
export const addWaterMeterURL = '/api/core/water/add_water_meter'
export const getWaterMetersURL = '/api/core/water/get_water_meters'
export const importWaterMetersURL = '/api/core/water/import_water_meters'
export const confirmCompanyNameURL = '/api/core/company_management/confirm_company_name'
export const getCompaniesURL = '/api/core/company_management/get_companies'
export const addCompanyURL = '/api/core/company_management/add_company'
export const addExistingCompanyToUserURL = '/api/core/user_management/add_a_existing_company_to_user'
export const addNewCompanyToUserURL = '/api/core/user_management/add_a_new_company_to_user'
export const getCompanyUsersURL = '/api/core/user_management/get_company_users'
export const confirmUserEmailURL = '/api/core/user_management/confirm_user_email'
export const getSitesURL = '/api/core/site_management/get_sites'
export const addSiteURL = '/api/core/site_management/add_site'
export const getProjectsURL = '/api/core/project_management/get_projects'
export const getTransactionsURL = ''
export const getWaterMeterAccountURL = ''
export const getAPIMetricsURL = '/api/core/api_management/get_metrics'