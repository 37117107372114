import { Link,useNavigate } from 'react-router-dom'
import Layout from '../component/layout'
function Facilities() {
  const navigate = useNavigate()
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/app/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript: void(0)">Facilities</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
        <div className="row">
        <div className="col-md-12" >
          <div style={{ float: 'right' }}>
            <button className="btn btn-primary" onClick={()=>{
               navigate('/app/add_new_facility')
            }}><i className="ti ti-plus pt-3"></i> Add New Facility</button>
          </div>
        </div>

      </div>
        </div>
        <div className="card-body">
          <div className="row">

            <div className="col-md-3">
              <Link to={"/facility"}>
                <div className="pc-component">
                  <div className="card mb-3 text-center" style={{ position: 'relative' }}>
                    <span className="badge bg-warning" style={{ position: 'absolute', top: 5, left: 5 }}>1</span>
                    <span className="badge bg-light-primary"> <i className="ti ti-home f-50 mt-3"></i></span>

                    <div className="card-body">
                      <h5 className="card-title">Toll</h5>
                      <p className="card-text"><span className="badge bg-light-primary">Estate</span></p>

                    </div>
                  </div>
                </div>
              </Link>

            </div>
            <div className="col-md-3">
              <Link to={"/facility"}>
                <div className="pc-component">
                  <div className="card mb-3 text-center">
                    <span className="badge bg-warning" style={{ position: 'absolute', top: 5, left: 5 }}>2</span>
                    <span className="badge bg-light-primary"> <i className="ti ti-building f-50 mt-3"></i></span>

                    <div className="card-body">
                      <h5 className="card-title">Knights Bridge</h5>
                      <p className="card-text"><span className="badge bg-light-info">Complex</span></p>

                    </div>
                  </div>
                </div>
              </Link>

            </div>
          </div>
        </div>
      </div>
      



    </Layout>

  )
}
export default Facilities