import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Layout from '../component/layout'

function AddNewCustomer() {
  
  return (
    <Layout>


      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/app"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/app/customer_management"}>Customer Management</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add New Customer
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/app/customer_management"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Facility<span style={{ color: 'red' }}>*</span></label>
                      <select className="mb-3 form-select" value={''} onChange={(e) => {
                        const value = e.target.value
                        
                      }}>

                        <option value="">-- Select --</option>
                        <option value="Toll">Toll</option>
                        <option value="Knights Bridge">Knights Bridge</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                    <label className="form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                      <select className="mb-3 form-select" value={''} onChange={(e) => {
                        const value = e.target.value
                        
                      }}>

                        <option value="">-- Select --</option>
                        <option value="A1">A1</option>
                        <option value="A2">A2</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label" >Customer Type</label>
                      <select className="form-control" value={''} onChange={(e) => {
                        const value = e.target.value
                       
                      }}>
                        <option value=''>-- Select --</option>
                        <option value='Home Owner'>Home Owner</option>
                        <option value='Tenant'>Tenant</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Full Name</label>
                      <input type="text" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                        
                      }} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input type="email" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                       
                      }} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Mobile Number</label>
                      <input type="number" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                       
                      }} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">ID Number / Passport Number</label>
                      <input type="number" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                       
                      }} />
                    </div>
                  </div>
                </div>



                <div className="text-end mt-4">
                  <button type="submit" className="btn btn-outline-secondary" onClick={()=>{}}>
                    Clear
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" onClick={()=>{}}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default AddNewCustomer