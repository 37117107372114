import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSpinner, inputForgotEmail } from '../../features/authentication/authenticationReducer'
import { makeRequest } from '../../utils/makeRequest';
import { toastify } from '../../utils/toast';
import { forgotPasswordURL } from '../../utils/urls';
import { useNavigate ,Link} from 'react-router-dom'

function ForgotPassword() {
  const forgotEmail = useSelector((state) => state.authenticationReducer.forgotEmail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendPasswordResetEmail = async () => {
  
    dispatch(updateSpinner(true))
    try {
      if (forgotEmail === '') {
        toastify('Email Address is required', 'error')
        dispatch(updateSpinner(false))
        return
      }
      const body = {
         email:forgotEmail
      }
      const response = await makeRequest(forgotPasswordURL,'POST',body)
      if(response.success === true){
        navigate('/check_email')
         dispatch(updateSpinner(false))
         dispatch(inputForgotEmail(''))
         return
      }
      else {
        toastify(response.error,'error')
        dispatch(updateSpinner(false))
        return
      }
    }
    catch (err) {
      toastify(err.message,'error')
      dispatch(updateSpinner(false))
      return

    }

  }
  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v2">
          <div className="auth-sidecontent">
            <img src="/assets/images/authentication/img-auth-sideimg.jpg" alt="images" className="img-fluid img-auth-side" />
          </div>
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
              <Link to={'#'}><img src="/assets/images/PayServeLogoFinal.png" alt="img" style={{width:300}} /></Link>
              <div className="d-flex justify-content-between align-items-end mb-4">
                  <h3 className="mb-0"><b>Forgot Password</b></h3>
                  <Link to={"/"} className="link-primary">Back to Login</Link>
                </div>
                <div className="mb-3">
                  <label className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="floatingInput" placeholder="Email Address" value={forgotEmail} onChange={(e) => {
                    dispatch(inputForgotEmail(e.target.value))
                  }} />
                </div>
                <p className="mt-4 text-sm text-muted">Do not forgot to check SPAM box.</p>
                <div className="d-grid mt-3">
                  <button type="button" className="btn btn-primary" onClick={handleSendPasswordResetEmail}>Send Password Reset Email</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ForgotPassword