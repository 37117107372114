import React, { useEffect } from 'react';
import Nav from './nav'
import Header from './header'
import Footer from './footer'
import { getItem } from 'localforage';
import { toastify } from '../../../utils/toast';

function Layout({ children }) {
  const checkUserExistInLocalStorage = async () => {
    const userExist = await getItem('USER')
  
    if (userExist === null || userExist === undefined) {
      toastify('You have been logged out, Kindly login', 'error')
      window.location.href = '/'
    }
  }
  useEffect(() => {
   // checkUserExistInLocalStorage()
  }, [])
  return (
    <>
      <Nav />
      <Header />
      <div className="pc-container">
        <div className="pc-content">
          {children}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Layout;