export const customers = [
    {
      fullname: "John Doe",
      address: `Toll - Unit `,
      mobile_phone: "+254 712 345 678",
      email: "johndoe@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Jane Smith",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 723 456 789",
      email: "janesmith@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Michael Otieno",
      address: `Toll - Unit `,
      mobile_phone: "+254 734 567 890",
      email: "michaelotieno@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Alice Njeri",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 745 678 901",
      email: "alicenjeri@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Brian Mwangi",
      address: `Toll - Unit `,
      mobile_phone: "+254 756 789 012",
      email: "brianmwangi@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Grace Wanjiku",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 767 890 123",
      email: "gracewanjiku@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "David Kamau",
      address: `Toll - Unit `,
      mobile_phone: "+254 778 901 234",
      email: "davidkamau@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Emily Achieng",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 789 012 345",
      email: "emilyachieng@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Charles Njoroge",
      address: `Toll - Unit `,
      mobile_phone: "+254 790 123 456",
      email: "charlesnjoroge@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
}]
export const units = [
    {
      unit: 1,
      block: "A",
      floor: 1
    },
    {
      unit: 2,
      block: "B",
      floor: 2
    },
    {
      unit: 3,
      block: "C",
      floor: 3
    },
    {
      unit: 4,
      block: "D",
      floor: 4
    },
    {
      unit: 5,
      block: "E",
      floor: 5
    },
    {
      unit: 6,
      block: "F",
      floor: 6
    },
    {
      unit: 7,
      block: "G",
      floor: 7
    },
    {
      unit: 8,
      block: "H",
      floor: 8
    },
    {
      unit: 9,
      block: "I",
      floor: 9
    },
    {
      unit: 10,
      block: "J",
      floor: 10
    }
  ];
export const watermeters = [
    {
      "meter_sn": "MTR001",
      "unit": "Unit 101",
      "reading": 123.45,
      "status": "Open",
      "last_updated": "2024-08-01T14:23:00Z"
    },
    {
      "meter_sn": "MTR002",
      "unit": "Unit 202",
      "reading": 678.90,
      "status": "Closed",
      "last_updated": "2024-07-31T09:12:00Z"
    },
    {
      "meter_sn": "MTR003",
      "unit": "Unit 303",
      "reading": 345.67,
      "status": "Open",
      "last_updated": "2024-07-30T16:45:00Z"
    },
    {
      "meter_sn": "MTR004",
      "unit": "Unit 404",
      "reading": 890.12,
      "status": "Closed",
      "last_updated": "2024-07-29T10:30:00Z"
    },
    {
      "meter_sn": "MTR005",
      "unit": "Unit 505",
      "reading": 234.56,
      "status": "Open",
      "last_updated": "2024-08-01T08:00:00Z"
    }
  ]
export const powerMeters = [
    {
      "meter_sn": "MTR006",
      "unit": "Unit 106",
      "reading": 456.78,
      "status": "On",
      "last_updated": "2024-08-01T15:00:00Z"
    },
    {
      "meter_sn": "MTR007",
      "unit": "Unit 207",
      "reading": 789.01,
      "status": "Off",
      "last_updated": "2024-07-31T10:00:00Z"
    },
    {
      "meter_sn": "MTR008",
      "unit": "Unit 308",
      "reading": 234.56,
      "status": "On",
      "last_updated": "2024-07-30T17:00:00Z"
    },
    {
      "meter_sn": "MTR009",
      "unit": "Unit 409",
      "reading": 567.89,
      "status": "Off",
      "last_updated": "2024-07-29T11:00:00Z"
    }]
  
  
  
