import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Layout from '../component/layout'

function AddNewUnit() {
  
  return (
    <Layout>


      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/unit_management"}>Unit Management</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add New Unit
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/facility/unit_management"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

              </div>
              <div className="container">
                <div className="row">
                 
                  

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Unit Number</label>
                      <input type="text" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                        
                      }} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Unit Type</label>
                       <select className="form-control">
                        <option>-- Select --</option>
                        <option>One Bedroom</option>
                        <option>Two Bedroom</option>
                        <option>Three Bedroom</option>
                       </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Block</label>
                      <input type="text" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                       
                      }} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Floor Number</label>
                      <input type="text" value={''} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                       
                      }} />
                    </div>
                  </div>
                </div>



                <div className="text-end mt-4">
                  <button type="submit" className="btn btn-outline-secondary" onClick={()=>{}}>
                    Clear
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" onClick={()=>{}}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default AddNewUnit