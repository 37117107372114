import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams,Link} from 'react-router-dom'
import { inputNewPassword, inputConfirmNewPassword, updateSpinner } from '../../features/authentication/authenticationReducer'
import { makeRequest } from '../../utils/makeRequest';
import { toastify } from '../../utils/toast';
import { resetPasswordURL } from '../../utils/urls';

function ResetPassword() {
  const newPassword = useSelector((state) => state.authenticationReducer.newPassword);
  const confirmNewPassword = useSelector((state) => state.authenticationReducer.confirmNewPassword);
  const { userId } = useParams();
  const dispatch = useDispatch();



  const HandleResetPassword = async () => {
    dispatch(updateSpinner(true))
    try {
      if(newPassword === ''){
        toastify(`New Password is required`, 'error');
        dispatch(updateSpinner(false))
        return
      }
      else if(confirmNewPassword === ''){
        toastify(`Confrim Password is required`, 'error');
        dispatch(updateSpinner(false))
        return
      }
      else if (newPassword !== confirmNewPassword) {
        toastify(`Passwords do not match`, 'error');
        dispatch(updateSpinner(false))
        return
      }
      const body = {
        password: newPassword,
        confirm_password: confirmNewPassword
      }
      const response = await makeRequest(resetPasswordURL + '/' + userId, 'POST', body)
      if (response.success === true) {
        toastify(`Password updated successfully`, 'success');
        dispatch(updateSpinner(false))
        dispatch(inputNewPassword(''))
        dispatch(inputConfirmNewPassword(''))
        return
      }
      else {
        toastify(response.error,'error')
        dispatch(updateSpinner(false))
        return
      }
    }
    catch (err) {
      toastify(err.message, 'error')
      dispatch(updateSpinner(false))
      return
    }
  }


  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v2">
          <div className="auth-sidecontent">
            <img src="/assets/images/authentication/img-auth-sideimg.jpg" alt="images" className="img-fluid img-auth-side" />
          </div>
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
              <Link to={"#"}><img src="/assets/images/PayServeLogoFinal.png" alt="img" style={{width:300}} /></Link>
                   <div className="mb-4">
                  <h3 className="mb-2"><b>Reset Password</b></h3>
                  <p className="text-muted">Please choose your new password</p>
                  
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input type="password" className="form-control" id="floatingInput" placeholder="Password" value={newPassword} onChange={(e) => {
                    dispatch(inputNewPassword(e.target.value))
                  }} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Confirm Password</label>
                  <input type="password" className="form-control" id="floatingInput1" placeholder="Confirm Password" value={confirmNewPassword} onChange={(e) => {
                    dispatch(inputConfirmNewPassword(e.target.value))
                  }} />
                </div>
                <div className="d-grid mt-4">
                  <button type="button" className="btn btn-primary" onClick={HandleResetPassword}>Reset Password</button>
                  
                </div>
                <div className="text-center mt-3">
        
                <Link to={'/'} className="link-primary">
                   Back to Login
                </Link>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ResetPassword