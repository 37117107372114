
import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
import {customers} from '../data/fake'
function CustomerManagement() {
     const navigate = useNavigate()
console.log(customers.length)
    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    const ViewCustomerTemplate = ()=>{
        return <button className="btn btn-warning"> View Customer</button>
    }
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/app/customer_management"}>Customer Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Customers</h5>

                                </div>
                                <div className='card-body'>
                                    <div className="row">

                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="row">
                                                        <div className="col-md-3 col-xs-12  mb-3">
                                                            <br />
                                                            <input className="form-control" placeholder="Search here" value={''} onChange={(e) => { }} />
                                                          
                                                        </div>
                                                        <div className="col-md-1 col-xs-12  mb-3">
                                                            <br />
                                                            <div className="btn-group-dropdown" style={{ float: 'right' }}>
                                                                <button
                                                                    className="btn btn-outline-default dropdown-toggle"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="ti ti-filter"></i>
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <Link className="dropdown-item" to="#!" onClick={() => { }}>
                                                                        All
                                                                    </Link>
                                                                    <Link className="dropdown-item" to="#!" onClick={() => { }}>
                                                                        Toll
                                                                    </Link>
                                                                    <Link className="dropdown-item" to="#!" onClick={() => { }}>
                                                                        Knights Bridge
                                                                    </Link>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-xs-12  mb-3">
                                                            <div style={{ float: 'right' }}>
                                                                <br />
                                                                <button className='btn btn-primary' onClick={()=>{
                                                                    navigate('/app/customer_management/add_new_customer')
                                                                }}>Add New Customer</button>
                                                                &nbsp;
                                                                <button className='btn btn-primary' onClick={()=>{
                                                                     navigate('/app/customer_management/import_customers')
                                                                }}>Import Customers</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="dt-responsive table-responsive">
                                                                <DataTable value={customers} header={header} emptyMessage="No customers found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                    
                                                                <Column field="" header=""></Column>
                                                                    <Column field="fullname" header="Full Names"></Column>
                                                                    <Column field="address" header="Address"></Column>
                                                                    <Column field="mobile_phone" header="Mobile Phone"></Column>
                                                                    <Column field="email" header="Email"></Column>
                                                                    <Column field="idno" header="Idno / Ppno"></Column>
                                                                    <Column field="status" header="Status"></Column>
                                                                    <Column field="type" header="type"></Column>
                                                                    <Column field="" body={ViewCustomerTemplate} header="Actions"></Column>
                                                                   
                                                                </DataTable>


                                                            </div>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default CustomerManagement