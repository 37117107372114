
import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getItem, clearStorage } from '../../../utils/localStorage';
function Nav() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate()
    const [userName, setFullname] = useState("")
    const [userRole, setRole] = useState("")

    const HandleLogOut = async () => {
        await clearStorage()
        navigate('/')
    }
    const getCurrentUser = async () => {
        try {
            const currentUser = await getItem('USER')
            if (currentUser) {
                const fullName = currentUser.user.fullName
                const role = currentUser.user.type
                setFullname(fullName)
                setRole(role)
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        getCurrentUser()

    }, [])
    return (
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <Link to={"/facility/"} className="b-brand text-primary">

                        <img
                            src="/assets/images/PayServeLogoFinal.png"
                            className="img-fluid logo-lg"
                            alt="logo"
                            style={{ width: 180 }}
                        />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">
                            v1.0
                        </span>
                    </Link>
                </div>
                <div className="navbar-content" style={{ overflowY: 'scroll' }}>
                    <div className="card pc-user-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        src="/assets/images/user/avatar-1.jpg"
                                        alt="user-image"
                                        className="user-avtar wid-45 rounded-circle"
                                    />
                                </div>
                                <div className="flex-grow-1 ms-3 me-2">
                                    <h6 className="mb-0">Jane Wanjiru</h6>
                                    <small>Administrator</small>
                                </div>
                                <Link
                                    className="btn btn-icon btn-link-secondary avtar"
                                    data-bs-toggle="collapse"
                                    to="#pc_sidebar_userlink"
                                >
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-sort-outline" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                                <div className="pt-3">

                                    <Link to={"/facility/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link>

                                    <Link to={"#!"} onClick={() => { HandleLogOut() }}>
                                        <i className="ti ti-power" />
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="pc-navbar mb-5">
                        <li className={`pc-item ${currentPath === '/app/' ? 'active' : ''}`}>
                            <Link to={'/app/'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-logout" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Home Page</span>


                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility' ? 'active' : ''}`}>
                            <Link to={'/facility/'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-home" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Dashboard</span>


                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/unit_management' ? 'active' : ''}`}>
                            <Link to={'/facility/unit_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-element-plus" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Unit Management</span>


                            </Link>
                        </li>





                        <li className="pc-item pc-caption">
                            <label>Utility Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/meter_management/' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/meter_management/'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Meter Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/billing' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/billing'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-story" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Billing</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-presentation-chart" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Lease Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/lease_management' ? 'active' : ''}`}>
                            <Link to={'/facility/lease_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Lease Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/lease_management/rent_invoicing' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/lease_management/rent_invoicing'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-story" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Rent Invoicing</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/lease_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/lease_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-status-up" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Levy Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/levy_management' ? 'active' : ''}`}>
                            <Link to={'/facility/levy_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-graph" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Levy Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/levy_management/levy_invoicing' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/levy_management/levy_invoicing'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-keyboard" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Levy Invoicing</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/levy_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/levy_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-presentation-chart" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Expense Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/expense_management' ? 'active' : ''}`}>
                            <Link to={'/facility/expense_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-layer" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Expense Management</span>
                            </Link>
                        </li>

                        <li className={`pc-item ${currentPath === '/facilitiy/expense_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/expense_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-shapes" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Visitor & Access Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/visitor_management/visitor_registration' ? 'active' : ''}`}>
                            <Link to={'/facility/visitor_management/visitor_registration'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-user-add" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Visitor Registration</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/visitor_management/guard_registration' ? 'active' : ''}`}>
                            <Link to={'/facility/visitor_management/guard_registration'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-profile-2user-outline" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Guard Registration</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/visitor_management/digital_logs' ? 'active' : ''}`}>
                            <Link to={'/facility/visitor_management/digital_logs'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-keyboard" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Digital Logs</span>
                            </Link>
                        </li>

                        <li className={`pc-item ${currentPath === '/facility/visitor_management/entries_and_exits' ? 'active' : ''}`}>
                            <Link to={'/facility/visitor_management/entries_and_exits'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-data" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Entries & Exits</span>
                            </Link>
                        </li>

                        <li className={`pc-item ${currentPath === '/facilitiy/visitor_management/access_control' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/visitor_management/access_control'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-lock-outline" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Access Control </span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Maintenance Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/maintenance_management/work_order_management' ? 'active' : ''}`}>
                            <Link to={'/facility/maintenance_management/work_order_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-simcard-2" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Work Order Management</span>
                            </Link>
                        </li>

                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/preventive_maitenance' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/preventive_maitenance'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-calendar-1" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Preventive Maintenance </span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/sla_management' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/sla_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-note-1" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Service Vendor & SLA Management </span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/asset_management' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/asset_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-layer" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Asset Management </span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/sports_and_spare_parts' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/sports_and_spare_parts'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-clipboard" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Stock & Spare Parts </span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/compliance_and_safety' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/compliance_and_safety'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-shield" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Compliance & Safety </span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Handover Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/handover_management/pre_handover_inspection' ? 'active' : ''}`}>
                            <Link to={'/facility/handover_management/pre_handover_inspection'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-mask" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Pre Handover Inspection</span>
                            </Link>
                        </li>


                        <li className={`pc-item ${currentPath === '/facilitiy/handover_management/post_handover_inspection' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/handover_management/post_handover_inspection'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-crop" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Post-Handover Inspection</span>
                            </Link>
                        </li>


                        <li className={`pc-item ${currentPath === '/facilitiy/handover_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/handover_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-mouse-circle" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports </span>
                            </Link>
                        </li>



                    </ul>
                </div>
            </div>
        </nav>

    )
}
export default Nav