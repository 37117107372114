
import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
import { units } from '../../app/data/fake';
function UnitManagement() {
     const navigate = useNavigate()

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/unit_management"}>Unit Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Units</h5>

                                </div>
                                <div className='card-body'>
                                    <div className="row">

                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="row">
                                                        <div className="col-md-3 col-xs-12  mb-3">
                                                            <br />
                                                            <input className="form-control" placeholder="Search here" value={''} onChange={(e) => { }} />
                                                          
                                                        </div>
                                                      
                                                        <div className="col-md-8 col-xs-12  mb-3">
                                                            <div style={{ float: 'right' }}>
                                                                <br />
                                                                <button className='btn btn-primary' onClick={()=>{
                                                                    navigate('/facility/unit_management/add_new_unit')
                                                                }}>Add New Unit</button>
                                                                &nbsp;
                                                                <button className='btn btn-primary' onClick={()=>{
                                                                     navigate('/facility/unit_management/import_units')
                                                                }}>Import Units</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="dt-responsive table-responsive">
                                                                <DataTable value={units} header={header} emptyMessage="No customers found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                          
                                                                    <Column field="unit" header="Unit"></Column>
                                                                    <Column field="block" header="Block"></Column>
                                                                    <Column field="floor" header="Floor"></Column>

                                                                </DataTable>


                                                            </div>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default UnitManagement