
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
function GuardRegistration() {


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/visitor_access_management/guard_registration"}>Guard Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                           
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default GuardRegistration